<template>
  <div class="relative" v-bind="$attrs">
    <div
      ref="scrollable"
      class="scrollable flex select-none snap-x snap-mandatory scroll-p-2 list-none overflow-x-auto whitespace-nowrap p-2"
      :class="{ 'cursor-grab': groups.length > 1 }"
    >
      <slot name="items" />
    </div>
    <slot name="arrows" :go-to-prev="goToPrev" :go-to-next="goToNext" />
    <div
      v-if="showArrows && groups.length > 1"
      class="flex flex-row items-center justify-center gap-2"
      :class="{ 'opacity-0': !isInitialized }"
    >
      <SwiperPrev :has-prev="hasPrev" :go-to-prev="goToPrev" />
      <SwiperNext :has-next="hasNext" :go-to-next="goToNext" />
    </div>
  </div>

  <slot name="bullets" :bullets="groups" :go-to="goTo" />
  <div
    v-if="showBullets && groups.length > 1"
    class="mt-6 flex flex-row items-center justify-center md:mt-8"
  >
    <SwiperBullets :groups="groups" :go-to="goTo" />
  </div>
  <div v-else-if="showBullets" class="mt-6 h-4 md:mt-8" />
</template>

<script setup lang="ts">
import useSwiper from './useSwiper';

defineProps({
  showArrows: {
    type: Boolean,
    required: false,
    default: true,
  },
  showBullets: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const scrollable = ref<HTMLElement | null>(null);
const isInitialized = ref(false);

const { groups, goTo, goToNext, goToPrev, hasNext, hasPrev } =
  useSwiper(scrollable);
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior-x: contain;
}
</style>
